<template>
  <div class="manage-users">
    <h3>Administrer brugere</h3>
    
    <div class="users-list">
      <div v-for="user in users" :key="user.id" class="user-card">
        <div class="user-header" @click="toggleUser(user.id)">
          <div class="user-basic-info">
            <span class="user-name">{{ user.displayName || user.email }}</span>
            <span class="user-role">({{ formatRole(user.role) }})</span>
            <span class="last-login">Seneste login: {{ formatLastLogin(user.lastLoginTime) }}</span>
          </div>
          <div class="user-actions-compact">
            <button @click.stop="confirmDelete(user)" class="delete-btn">
              <i class="fas fa-trash"></i>
            </button>
            <span class="expand-icon" :class="{ 'is-expanded': expandedUsers[user.id] }">▼</span>
          </div>
        </div>

        <div v-if="expandedUsers[user.id]" class="user-details">
          <div class="user-info-section">
            <div class="input-group">
              <label>Email:</label>
              <span>{{ user.email }}</span>
            </div>
            <div class="input-group">
              <label>Navn:</label>
              <input 
                type="text" 
                v-model="userDisplayNames[user.id]" 
                placeholder="Angiv navn"
              >
            </div>
            <div class="input-group">
              <label>Rolle:</label>
              <select v-model="user.role">
                <option value="user">Bruger</option>
                <option value="admin">Admin</option>
                <option value="owner">Ejer</option>
              </select>
            </div>
          </div>
          
          <div class="businesses-section">
            <h4>Tilknyttede restauranter</h4>
            <div class="businesses-list">
              <div v-for="(orgData, orgId) in user.organizations" 
                   :key="orgId" 
                   class="org-businesses"
              >
                <div v-for="business in orgData.businesses" 
                     :key="business" 
                     class="business-item"
                >
                  {{ getBusinessName(orgId, business) }}
                  <button @click="removeBusinessAccess(user.id, orgId, business)" 
                          class="remove-btn"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <button @click="showAddBusinessModal(user)" class="add-btn">
              <i class="fas fa-plus"></i> Tilføj forretning
            </button>
          </div>

          <div class="save-section">
            <button 
              @click="saveUserChanges(user)" 
              class="save-all-btn"
              :disabled="isSaving"
            >
              <span v-if="isSaving" class="loader"></span>
              <span v-else><i class="fas fa-save"></i> Gem ændringer</span>
            </button>
            <div v-if="saveMessage[user.id]" :class="['save-message', saveMessage[user.id].type]">
              {{ saveMessage[user.id].text }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Business Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h4>Tilføj forretning til {{ selectedUser?.email }}</h4>
        
        <div class="org-selection">
          <select v-model="selectedOrg">
            <option value="">Vælg organisation</option>
            <option v-for="org in organizations" 
                    :key="org.orgName" 
                    :value="org">
              {{ org.orgName }}
            </option>
          </select>

          <div v-if="selectedOrg" class="business-selection">
            <div v-for="business in selectedOrg.businesses" 
                 :key="business.name" 
                 class="business-checkbox">
              <input type="checkbox" 
                     :id="business.name"
                     v-model="selectedBusinesses"
                     :value="business.name">
              <label :for="business.name">{{ business.name }}</label>
            </div>
          </div>
        </div>

        <div class="modal-actions">
          <button @click="addBusinessesToUser" class="save-btn">Gem</button>
          <button @click="closeModal" class="cancel-btn">Annuller</button>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal">
      <div class="modal-content">
        <h4>Slet bruger</h4>
        <p>Er du sikker på, at du vil slette {{ userToDelete?.email }}?</p>
        <p class="warning">Denne handling kan ikke fortrydes!</p>
        
        <div class="modal-actions">
          <button 
            @click="deleteUser" 
            class="delete-btn"
            :disabled="isDeleting"
          >
            <span v-if="isDeleting" class="loader"></span>
            <span v-else>Slet</span>
          </button>
          <button 
            @click="closeDeleteModal" 
            class="cancel-btn"
            :disabled="isDeleting"
          >
            Annuller
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db, doc, setDoc, getDoc } from '@/utils/firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default {
  name: 'ManageUsers',
  
  props: {
    organizations: {
      type: Array,
      required: true
    }
  },

  setup(props) {
    const users = ref([]);
    const expandedUsers = ref({});
    const showModal = ref(false);
    const showDeleteModal = ref(false);
    const selectedUser = ref(null);
    const userToDelete = ref(null);
    const selectedOrg = ref(null);
    const selectedBusinesses = ref([]);
    const isDeleting = ref(false);
    const userDisplayNames = ref({});
    const isSaving = ref(false);
    const saveMessage = ref({});

    const getBusinessName = (orgId, businessId) => {
      const org = props.organizations.find(o => o.orgName === orgId);
      if (!org) return businessId;
      
      const business = org.businesses.find(b => b.name === businessId);
      return business?.data?.businessName || businessId;
    };

    const fetchUsers = async () => {
      try {
        const functions = getFunctions(undefined, 'europe-west1');
        const getUsersWithLoginTime = httpsCallable(functions, 'getAdminUsersList');
        const result = await getUsersWithLoginTime();
        
        // Get users with their basic info
        users.value = result.data.users;
        
        // Fetch additional user data from Firestore for each user
        for (const user of users.value) {
          const userDoc = await getDoc(doc(db, 'users', user.id));
          const userData = userDoc.data();
          user.displayName = userData?.displayName || '';
          userDisplayNames.value[user.id] = userData?.displayName || '';
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const toggleUser = (userId) => {
      expandedUsers.value[userId] = !expandedUsers.value[userId];
    };

    const updateUserRole = async (userId, newRole) => {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { role: newRole }, { merge: true });
    };

    const removeBusinessAccess = async (userId, orgId, businessId) => {
      const userRef = doc(db, 'users', userId);
      const userData = await getDoc(userRef);
      const updatedOrgs = { ...userData.data().organizations };
      
      const businesses = updatedOrgs[orgId].businesses.filter(b => b !== businessId);
      
      if (businesses.length === 0) {
        delete updatedOrgs[orgId];
      } else {
        updatedOrgs[orgId].businesses = businesses;
      }
      
      await setDoc(userRef, { organizations: updatedOrgs }, { merge: true });
      await fetchUsers();
    };

    const showAddBusinessModal = (user) => {
      selectedUser.value = user;
      selectedOrg.value = null;
      selectedBusinesses.value = [];
      showModal.value = true;
    };

    const addBusinessesToUser = async () => {
      if (!selectedUser.value || !selectedOrg.value || selectedBusinesses.value.length === 0) return;

      const userRef = doc(db, 'users', selectedUser.value.id);
      const userData = await getDoc(userRef);
      const currentOrgs = userData.data().organizations || {};

      const updatedOrgs = {
        ...currentOrgs,
        [selectedOrg.value.orgName]: {
          businesses: [
            ...(currentOrgs[selectedOrg.value.orgName]?.businesses || []),
            ...selectedBusinesses.value
          ]
        }
      };

      await setDoc(userRef, { organizations: updatedOrgs }, { merge: true });
      await fetchUsers();
      closeModal();
    };

    const confirmDelete = (user) => {
      userToDelete.value = user;
      showDeleteModal.value = true;
    };

    const deleteUser = async () => {
      if (!userToDelete.value) return;
      
      isDeleting.value = true;
      try {
        const functions = getFunctions(undefined, 'europe-west1');
        const deleteUserCompletely = httpsCallable(functions, 'deleteUserCompletely');
        
        await deleteUserCompletely({ userId: userToDelete.value.id });
        await fetchUsers();
        closeDeleteModal();
      } catch (error) {
        console.error('Error deleting user:', error);
        // You might want to show an error message to the user
      } finally {
        isDeleting.value = false;
      }
    };

    const closeModal = () => {
      showModal.value = false;
      selectedUser.value = null;
      selectedOrg.value = null;
      selectedBusinesses.value = [];
    };

    const closeDeleteModal = () => {
      showDeleteModal.value = false;
      userToDelete.value = null;
    };

    const formatLastLogin = (timestamp) => {
      if (!timestamp) return 'Aldrig';
      const date = new Date(timestamp);
      return date.toLocaleString('da-DK', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    };

    const formatRole = (role) => {
      switch (role) {
        case 'user':
          return 'Bruger';
        case 'admin':
          return 'Admin';
        case 'owner':
          return 'Ejer';
        default:
          return role;
      }
    };

    const saveUserChanges = async (user) => {
      isSaving.value = true;
      saveMessage.value[user.id] = null;
      
      try {
        // Update display name
        if (userDisplayNames.value[user.id]) {
          const functions = getFunctions(undefined, 'europe-west1');
          const updateUserDisplayName = httpsCallable(functions, 'updateUserDisplayName');
          await updateUserDisplayName({ 
            userId: user.id, 
            displayName: userDisplayNames.value[user.id] 
          });
        }

        // Update role
        const userRef = doc(db, 'users', user.id);
        await setDoc(userRef, { role: user.role }, { merge: true });

        saveMessage.value[user.id] = {
          type: 'success',
          text: 'Ændringer gemt'
        };
        
        await fetchUsers(); // Refresh the users list
      } catch (error) {
        console.error('Error saving changes:', error);
        saveMessage.value[user.id] = {
          type: 'error',
          text: 'Der opstod en fejl ved gem'
        };
      } finally {
        isSaving.value = false;
        // Clear success message after 3 seconds
        if (saveMessage.value[user.id]?.type === 'success') {
          setTimeout(() => {
            saveMessage.value[user.id] = null;
          }, 3000);
        }
      }
    };

    onMounted(() => {
      fetchUsers();
    });

    return {
      users,
      expandedUsers,
      showModal,
      showDeleteModal,
      selectedUser,
      userToDelete,
      selectedOrg,
      selectedBusinesses,
      getBusinessName,
      toggleUser,
      updateUserRole,
      removeBusinessAccess,
      showAddBusinessModal,
      addBusinessesToUser,
      confirmDelete,
      deleteUser,
      closeModal,
      closeDeleteModal,
      formatLastLogin,
      formatRole,
      userDisplayNames,
      isSaving,
      saveMessage,
      saveUserChanges
    };
  }
};
</script>

<style scoped>
.manage-users {
  margin-top: 2rem;
  padding: 1rem;
}

.user-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.user-basic-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: bold;
}

.user-role {
  color: #666;
}

.user-actions-compact {
  display: flex;
  gap: 0.5rem;
}

.delete-btn {
  background: #cc0000;
  color: white;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
}

.warning {
  color: #cc0000;
  font-weight: bold;
}

.delete-btn {
  background: #cc0000;
  color: white;
}

.save-btn {
  background: #005432;
  color: white;
}

button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.business-checkbox {
  margin: 0.5rem 0;
}

.last-login {
  font-size: 0.9em;
  color: var(--grey);
  display: block;
  margin-top: 0.25rem;
}

.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.user-details {
  padding: 2rem;
  border-top: 1px solid #eee;
  background: #f9f9f9;
}

.user-info-section {
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;
}

.input-group {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  gap: 1rem;
}

.input-with-button {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.input-with-button input,
.input-with-button select {
  flex: 1;
}

.save-btn {
  background: #005432;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.save-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.businesses-section {
  margin-top: 3rem;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.businesses-list {
  margin-top: 1.5rem;
  display: grid;
  gap: 1rem;
}

.business-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 6px;
  border: 1px solid #eee;
}

.remove-btn {
  background: #cc0000;
  color: white;
  border: none;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-btn {
  margin-top: 1rem;
  background: #005432;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.save-section {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.save-all-btn {
  background: #005432;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  min-width: 150px;
  justify-content: center;
}

.save-all-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.save-message {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-align: center;
}

.save-message.success {
  background: #e0f7e6;
  color: #005432;
}

.save-message.error {
  background: #ffe6e6;
  color: #cc0000;
}

.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
</style>