import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyD05Fd5GBDCeB5xCPsKoJB7exTW1nh8ZBA",
  authDomain: "databite-website-config.firebaseapp.com",
  projectId: "databite-website-config",
  storageBucket: "databite-website-config.appspot.com",
  messagingSenderId: "329374801491",
  appId: "1:329374801491:web:1c72ad7a0743f954fc30a6",
  measurementId: "G-N84M97L8P2"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// If you're using local emulator
if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export { 
    functions,
    auth, 
    db,
    doc,
    getDoc,
    setDoc,
    deleteDoc,
    onAuthStateChanged
};