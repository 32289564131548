<template>
  <div class="container">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-content">
        <h1>Data-drevet med Databite</h1>
        <p class="description">
         Det kan være svært at holde styr på al sin data fra et utal af platforme - salgsdata, gæsteanmeldelser og lønomkostninger. Databite samler data i én enkel og overskuelig platform. 
        </p>
        <div class="hero-cta">
          <GreenButton @click="$emit('start-trial')">Få en demo</GreenButton>
        </div>
      </div>
    </section>

    <!-- Main Features -->
    <section class="features">
      <h2>Vores standard produkter</h2>
      <div class="features-grid">
        <div 
          v-for="(feature, index) in [
            {
              title: 'Forecast',
              description: 'Ved brug af jeres historiske data og en række nøjeudvalgte faktorer, udregner vi en prognose for jeres fremtidige omsætning.',
              expandedContent: 'Detailed forecast information goes here...',
              buttonText: 'Læs mere'
            },
            {
              title: 'Medarbejderplanlægning',
              description: 'Vi hjælper med vagtplanlægning, ved at sammenligne jeres vagtplaner med omsætningsforecast.',
              expandedContent: 'Detailed employee planning information goes here...',
            },
            {
              title: 'Gæsteanmeldelser',
              description: 'Tilpas bemandingen til dine travle perioder. Se lønomkostninger i forhold til omsætning og optimer dine vagtplaner.',
              expandedContent: 'Detailed review information goes here...',
            },
            {
              title: 'Ad-hoc rapporter',
              description: 'Restauranter er ofte unikke og med forskellige behov. Med vores opsætning og jeres data, kan vi lave langt mere end det der vises i vores standard produkter. Vi hjælper med at bygge skræddersyede rapporter der matcher jeres behov.',
              expandedContent: 'Detailed report information goes here...',
              buttonText: 'Se eksempler på rapporter'
            }
          ]" 
          :key="index"
          class="feature-card"
          :class="{ 
            'expanded': expandedCard === index
          }"
          @click="toggleCard(index)"
        >
          <div class="feature-content">
            <div class="feature-header">
              <h3>{{ feature.title }}</h3>
              <span class="expand-indicator" :class="{ 'expanded': expandedCard === index }">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 9L12 16L5 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </div>
            <p>{{ feature.description }}</p>
            <div v-if="feature.buttonText" class="hero-cta">
              <GreenButton @click.stop="$emit('start-trial')">{{ feature.buttonText }}</GreenButton>
            </div>
          </div>
          <div v-if="expandedCard === index" class="expanded-content">
            <img src="@/assets/images/tapet_gron.svg" alt="Background pattern" class="expanded-bg">
            <div class="expanded-inner">
              <p>{{ feature.expandedContent }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Value Proposition -->
    <section class="value-prop">
      <h2>Hvorfor Databite?</h2>
      <ul class="value-list">
        <li>Designet specifikt til restaurationsbranchen.</li>
        <li>Intuitivt produkt, der er nemt at komme i gang med.</li>
        <li>Fortid og fremtid - vi hjælper både med at vise performance tal, og med at planlægge fremtiden.</li>
        <li>En dansk virksomhed, med en ambition om at hjælpe så mange som muligt med de drilske dele af driften.</li>
      </ul>
    </section>

    <!-- CTA Section -->
    <section class="cta-section">
      <h2>Hvad kræver det at komme i gang?</h2>
      <p>Vi har integrationer med enkelte platforme. Vi er ikke begrænset til disse, og tilføjer gerne nye for at få jer med i folden.
        Vil I gerne høre mere, høre om vores muligheder med jeres platforme, eller melde jer til, så kan I udfylde vores kontaktformular.
      </p>
      <div class="hero-cta">
      <GreenButton @click="$emit('contact-us')">Kontaktformular</GreenButton>
      <GreenButton @click="$emit('contact-us')">Integrerede platforme</GreenButton>
      <GreenButton @click="$emit('contact-us')">Hvad kræver det</GreenButton>
      </div>
    </section>
  </div>
</template>

<script>
import GreenButton from '@/components/GreenButton.vue'

export default {
  name: "FrontPage",
  components: {
    GreenButton
  },
  data() {
    return {
      expandedCard: null
    }
  },
  methods: {
    toggleCard(index) {
      if (this.expandedCard === index) {
        this.expandedCard = null;
      } else {
        this.expandedCard = index;
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem;
  flex-direction: column;
  gap: 4rem;
}

.hero {
  padding: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero h1 {
  font-family: 'Questrial', sans-serif;
  font-size: 3.5rem;
  color: var(--primary-text-color);
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 1.5rem;
  color: var(--button-color);
  margin-bottom: 1.5rem;
}

.description {
  font-size: 1.25rem;
  color: var(--grey);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.features {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.features h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2.5rem;
  color: var(--primary-text-color);
  margin-bottom: 2rem;
  text-align: center;
}

.feature-card {
  background: var(--card-background);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px var(--shadow-color);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  grid-column: span 1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card.expanded {
  grid-column: 1 / -1;
  grid-row: auto;
  order: -1;
  z-index: 2;
  min-height: 400px;
}

.feature-card:not(.expanded) {
  transform: scale(1);
  opacity: 1;
}

.features-grid:has(.expanded) .feature-card:not(.expanded) {
  transform: translateY(20px);
  opacity: 0.7;
}

.expanded-content {
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.3s forwards;
  animation-delay: 0.1s;
  margin-top: 2rem;
  position: relative;
}

.expanded-inner {
  position: relative;
  z-index: 1;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
}

.expanded-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  z-index: 0;
  animation: fadeIn 0.3s forwards;
  animation-delay: 0.1s;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.1;
  }
}

.feature-content {
  position: relative;
  z-index: 1;
}

.feature-card h3 {
  color: var(--button-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: 'Questrial', sans-serif;
}

.feature-card p {
  color: var(--grey);
  line-height: 1.6;
}

.value-prop {
  background: var(--background-color);
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

.value-prop h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2.5rem;
  color: var(--primary-text-color);
  margin-bottom: 2rem;
  text-align: center;
}

.value-list {
  list-style: none;
  padding: 0;
  max-width: 600px;
  margin: 0 auto;
}

.value-list li {
  font-size: 1.25rem;
  color: var(--grey);
  margin-bottom: 1.5rem;
  padding-left: 2rem;
  position: relative;
}

.value-list li::before {
  content: "→";
  position: absolute;
  left: 0;
  color: var(--button-color);
}

.cta-section {
  text-align: center;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta-section h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2.5rem;
  color: var(--primary-text-color);
  margin-bottom: 1rem;
}

.cta-section p {
  font-size: 1.25rem;
  color: var(--grey);
  margin-bottom: 2rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
}

.feature-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.expand-indicator {
  color: var(--button-color);
  transition: transform 0.3s ease;
}

.expand-indicator.expanded {
  transform: rotate(-180deg);
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .value-list li {
    font-size: 1.1rem;
  }

  .container {
    padding: 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .features-grid:has(.expanded) .feature-card:not(.expanded) {
    transform: translateY(40px);
  }
}
</style>
