<template>
  <div class="dashboard">
    <div class="report-embed">
      <iframe 
        v-if="embedUrl"
        width="100%" 
        height="100%" 
        :src="embedUrl"
        frameborder="0" 
        style="border:0;" 
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
      </iframe>
      <div v-else class="loading">Loading...</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'DashBoard',
  setup() {
    const store = useStore();
    const embedUrl = ref('');

    onMounted(async () => {
      // Get the first business from the user's organizations
      const organizations = store.state.organizations;
      if (organizations && organizations.length > 0) {
        const firstOrg = organizations[0];
        if (firstOrg.businesses && firstOrg.businesses.length > 0) {
          const firstBusiness = firstOrg.businesses[0];
          
          // Create params object with correct property names
          const params = {
            "ds0.oid": firstOrg.orgName,
            "ds0.rid": firstBusiness.name
          };

          // Encode parameters
          const paramsAsString = JSON.stringify(params);
          const encodedParams = encodeURIComponent(paramsAsString);

          // Construct the full URL
          const reportId = "dc187eb6-5add-470d-afd3-ce91ce8742ae";
          const pageId = "dBMbE";
          const baseUrl = `https://lookerstudio.google.com/embed/reporting/${reportId}/page/${pageId}`;
          embedUrl.value = `${baseUrl}?params=${encodedParams}`;
        }
      }
    });

    return {
      embedUrl
    };
  }
};
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color);
  padding: 1rem;
}

.report-embed {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.loading {
  color: var(--grey);
  font-size: 1.2rem;
}
</style>
