<template>
  <div class="logged-in-home-page">
    <div class="page-content">
      <!-- Header Section -->
      <header class="header-section">
        <h1>Velkommen tilbage</h1>
        <p>
          Tag et overfladisk kig på restauranternes nøgletal, eller grav dig dybere ned i analyserne i dette overblik.
        </p>
      </header>

      <!-- Quick Navigation Blocks -->
      <section class="quick-nav">
        <router-link to="/forecast" class="nav-block">
          <i class="fas fa-calendar-alt"></i>
          <h3>Forecast & lønudgifter</h3>
          <p>Se forecast og planlæg vagter</p>
        </router-link>

        <div class="nav-block disabled">
          <i class="fas fa-comments"></i>
          <h3>Gæstehenvendelser</h3>
          <p>Få feedback fra dine gæster</p>
          <span class="coming-soon">Kommer snart</span>
        </div>

        <router-link to="/rapporter" class="nav-block">
          <i class="fas fa-chart-line"></i>
          <h3>Rapporter</h3>
          <p>Detaljerede rapporter</p>
        </router-link>

        <router-link to="/business" class="nav-block">
          <i class="fas fa-store"></i>
          <h3>Forretninger</h3>
          <p>Se dine restauranter</p>
        </router-link>
      </section>

      <!-- Quick Overview Section -->
      <section class="quick-overview">
        <h2>Dagens salg</h2>
        <div class="quick-overview-cards">
          <div 
            v-for="business in userBusinesses" 
            :key="business.id"
            class="overview-card"
          >
            <h3>{{ business.businessData?.businessName }}</h3>
            <div class="sales-bars">
              <div class="sales-bar-container">
                <!-- Progress bar background with forecast marker -->
                <div class="forecast-marker" 
                  :style="{ left: '100%', color: business.businessData?.colorScheme?.primary || '#005432' }"
                >
                  {{ formatCurrency(getDailyTarget(business)) }}
                </div>
                
                <!-- Main sales bar -->
                <div 
                  class="sales-progress main"
                  :style="{
                    width: `${((business.businessData?.dailySales?.paid?.amount || 0) / getDailyTarget(business)) * 100}%`,
                    backgroundColor: business.businessData?.colorScheme?.primary || '#005432'
                  }"
                ></div>
                <!-- Unpaid portion -->
                <div 
                  class="sales-progress unpaid"
                  :style="{
                    left: `${((business.businessData?.dailySales?.paid?.amount || 0) / getDailyTarget(business)) * 100}%`,
                    width: `${((business.businessData?.dailySales?.notPaid?.amount || 0) / getDailyTarget(business)) * 100}%`,
                    backgroundColor: '#FF9999'
                  }"
                ></div>
              </div>
            </div>
            <div class="sales-legend">
              <div class="amount-container">
                <div class="sales-comparison">
                  <p class="overview-value">
                    {{ formatCurrency(getTotalDailySales(business)) }}
                    <span class="comparison-indicator" 
                      :style="{ 
                        color: business.businessData?.colorScheme?.primary || '#005432',
                        backgroundColor: `${business.businessData?.colorScheme?.primary || '#005432'}10`
                      }"
                    >
                      {{ Math.round((getTotalDailySales(business) / getDailyTarget(business) * 100)) }}%
                    </span>
                  </p>
                </div>
                <p class="unpaid-note" v-if="business.businessData?.dailySales?.notPaid?.amount">
                  ({{ formatCurrency(business.businessData?.dailySales?.notPaid?.amount) }} ubetalt)
                </p>
              </div>
              <p class="overview-subtitle">ud af dagens forecast: {{ formatCurrency(getDailyTarget(business)) }}</p>
              <p class="latest-order" v-if="formatLatestOrderDate(business)">
                Dato: {{ formatLatestOrderDate(business) }}
              </p>
            </div>
            <router-link 
              :to="`/business/${encodeURIComponent(business.businessData?.businessName)}/sales`" 
              class="business-details-button"
            >
              <GreenButton borderRadius="8px">Se dagens omsætning</GreenButton>
            </router-link>
          </div>
        </div>
      </section>

      <!-- Main Content Sections -->
      <main class="main-content">
        <!-- Check if user has businesses -->
        <section v-if="userBusinesses.length > 0">
          <header class="section-header">
            <h2>Lyn-overblik</h2>
            <p>Se nøgletal for dine restauranter. Udvid for at se mere detaljeret information</p>
          </header>

          <div class="business-grid">
            <!-- Overview Dashboard for each business -->          
            <section
              v-for="business in userBusinesses"
              :key="business.id"
              class="overview-dashboard"
              :class="{ 'expanded': expandedBusinesses[business.id] }"
              @click="toggleBusinessExpand(business.id)"
            >
              <div class="dashboard-header">
                <h2>{{ business.businessData?.businessName }}</h2>
                <span class="expand-indicator" :class="{ 'expanded': expandedBusinesses[business.id] }">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </div>

              <!-- Always visible content -->
              <div class="basic-metrics">
                <div class="metric-item">
                  <span class="metric-label">Index</span>
                  <span class="metric-value" :style="{ color: business.businessData?.colorScheme?.primary || '#005432' }">
                    {{ business.businessData?.prevWeekSales?.index || 0 }}
                  </span>
                </div>
              </div>

              <!-- Expandable content -->
              <div class="expanded-content" v-if="expandedBusinesses[business.id]">
                <div class="metrics-grid">
                  <!-- Weekly Sales and Salary -->
                  <div class="metrics-card">
                    <h4>Sidste 7 dagens nøgletal</h4>
                    <div class="key-metrics">
                      <div class="metric-item">
                        <span class="metric-label">Omsætning</span>
                        <span class="metric-value" :style="{ color: business.businessData?.colorScheme?.primary || '#005432' }">
                          {{ formatCurrency(getWeeklyTotal(business.businessData?.prevWeekSales)) }}
                        </span>
                      </div>
                      <div class="metric-divider"></div>
                      <div class="metric-item">
                        <span class="metric-label">Lønprocent</span>
                        <span 
                          class="metric-value" 
                          :style="{ color: calculateSalaryPercentage(business) > (business.businessData?.salaryTarget?.[getCurrentMonth()] || 0) ? '#FF9999' : '#005432' }"
                        >
                          {{ calculateSalaryPercentage(business) }}%
                        </span>
                        <span class="metric-target">Mål for {{ getCurrentMonthName() }}: {{ business.businessData?.salaryTarget?.[getCurrentMonth()] || 0 }}%</span>
                      </div>
                    </div>
                  </div>

                  <!-- Customer Reviews -->
                  <div class="metrics-card" v-if="business.businessData?.reviews">
                    <h4>Gæsteanmeldelser</h4>
                    <div class="reviews-content">
                      <div class="review-score">
                        <span class="score-value">{{ business.businessData?.reviews?.averageScore || 0 }}/5</span>
                      </div>
                      <div class="summary-container">
                        <p class="review-summary">
                          {{ business.businessData?.reviews?.commentSummary || 'Ingen anmeldelser endnu' }}
                        </p>
                        <span class="ai-note">AI-genereret opsummering af seneste anmeldelser</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Sales Bar Chart -->
                <div v-if="business.businessData?.prevWeekSales" class="sales-chart">
                  <h4>Sidste 7 dages salg</h4>
                  <v-chart 
                    class="chart" 
                    :option="getChartOption(
                      business.businessData.prevWeekSales, 
                      business.businessData.prevWeekExpenses,
                      business.businessData.colorScheme?.primary
                    )" 
                    autoresize
                  />
                </div>
              </div>
            </section>
          </div>
        </section>

        <!-- Message if user has no businesses -->
        <section v-else>
          <p>Du har ingen tilknyttede restauranter.</p>
        </section>

      </main>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import GreenButton from '../components/GreenButton.vue';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { BarChart, GaugeChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

// Register ECharts components
use([
  CanvasRenderer,
  BarChart,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  GridComponent
]);

export default {
  name: 'LoggedInHomePage',
  components: {
    GreenButton,
    VChart,
  },
  setup() {
    const store = useStore();
    const expandedBusinesses = ref({});

    const userName = computed(() => store.state.user?.displayName || 'Bruger');
    const organizations = computed(() => store.state.organizations || []);

    const userBusinesses = computed(() => {
      const businesses = [];
      organizations.value.forEach(org => {
        org.businesses.forEach(business => {
          businesses.push({
            orgName: org.orgName,
            id: business.name,
            businessData: business.data,
          });
        });
      });
      return businesses;
    });

    const toggleBusinessExpand = (businessId) => {
      expandedBusinesses.value[businessId] = !expandedBusinesses.value[businessId];
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString('da-DK', { weekday: 'short' });
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK',
        maximumFractionDigits: 0
      }).format(amount);
    };

    const getSortedSales = (salesData) => {
      return Object.entries(salesData)
        .filter(([key]) => key !== 'index')
        .map(([date, sales]) => ({ date, sales }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    };

    const getChartOption = (salesData, expensesData, primaryColor) => {
      const sortedData = getSortedSales(salesData);
      const sortedExpenses = expensesData ? 
        sortedData.map(item => expensesData[item.date] || 0) : 
        [];

      return {
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '15%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            const sales = params[0].value;
            const expenses = params[1].value;
            return `Omsætning: ${formatCurrency(sales)}<br/>Udgifter: ${formatCurrency(expenses)}`;
          }
        },
        legend: {
          data: ['Omsætning', 'Udgifter'],
          top: 0
        },
        xAxis: {
          type: 'category',
          data: sortedData.map(item => formatDate(item.date)),
          axisLabel: { color: '#005432' }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: (value) => formatCurrency(value),
            color: '#005432'
          }
        },
        series: [
          {
            name: 'Omsætning',
            data: sortedData.map(item => item.sales),
            type: 'bar',
            itemStyle: { color: primaryColor || '#005432' },
            label: {
              show: true,
              position: 'top',
              formatter: (params) => formatCurrency(params.value),
              color: '#003224'
            }
          },
          {
            name: 'Udgifter',
            data: sortedExpenses,
            type: 'bar',
            itemStyle: { color: '#FF9999' },
            label: {
              show: true,
              position: 'top',
              formatter: (params) => formatCurrency(params.value),
              color: '#003224'
            }
          }
        ]
      };
    };

    const getTotalDailySales = (business) => {
      const dailySales = business.businessData?.dailySales;
      if (!dailySales) return 0;
      return (dailySales.paid?.amount || 0) + (dailySales.notPaid?.amount || 0);
    };

    const getDailyTarget = (business) => {
      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
      const forecastData = business.businessData?.comingWeek?.[today];
      
      if (forecastData?.arimaTs) {
        return Math.round(forecastData.arimaTs);
      }
      
      // Fallback to previous week's average if no forecast is available
      const prevWeekSales = business.businessData?.prevWeekSales;
      if (!prevWeekSales) return 0;
      const values = Object.values(prevWeekSales);
      const average = values.reduce((acc, val) => acc + val, 0) / values.length;
      return Math.round(average);
    };

    const getProgressPercentage = (business) => {
      const total = getTotalDailySales(business);
      const target = getDailyTarget(business);
      return target ? Math.round((total / target) * 100) : 0;
    };

    const getWeeklyTotal = (salesData) => {
      if (!salesData) return 0;
      return Object.values(salesData).reduce((sum, value) => sum + value, 0);
    };

    const calculateSalaryPercentage = (business) => {
      const totalSales = getWeeklyTotal(business.businessData?.prevWeekSales);
      const totalExpenses = getWeeklyTotal(business.businessData?.prevWeekExpenses);
      if (!totalSales) return 0;
      return Math.round((totalExpenses / totalSales) * 100);
    };

    const getCurrentMonth = () => {
      const month = new Date().getMonth() + 1;
      return month.toString().padStart(2, '0'); // This will convert 1 to "01", 2 to "02", etc.
    };

    const getCurrentMonthName = () => {
      return new Date().toLocaleString('da-DK', { month: 'long' });
    };

    const formatLatestOrderDate = (business) => {
      const paid = business.businessData?.dailySales?.paid?.latestOrderTime;
      const notPaid = business.businessData?.dailySales?.notPaid?.latestOrderTime;
      
      // Get the most recent date
      const dates = [paid, notPaid].filter(Boolean);
      if (!dates.length) return null;
      
      const latestDate = new Date(Math.max(...dates.map(date => new Date(date))));
      return latestDate.toLocaleDateString('da-DK');
    };

    return {
      userName,
      userBusinesses,
      expandedBusinesses,
      toggleBusinessExpand,
      formatDate,
      formatCurrency,
      getSortedSales,
      getChartOption,
      getTotalDailySales,
      getDailyTarget,
      getProgressPercentage,
      getWeeklyTotal,
      calculateSalaryPercentage,
      getCurrentMonth,
      getCurrentMonthName,
      formatLatestOrderDate,
    };
  },
};
</script>

<style scoped>
.logged-in-home-page {
  padding: 2rem;
  background-color: var(--background-color);
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
}

.page-content {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-section {
  width: 100%;
  text-align: center;
}

.header-section h1 {
  font-family: 'Questrial', sans-serif;
  font-size: 2.5rem;
}

.header-section h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 1.5rem;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  margin: 0;
}

section {
  margin-bottom: 1.5rem;
  background: var(--card-background);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--shadow-color);
  width: 100%;
  box-sizing: border-box;
}

section h2 {
  font-family: 'Questrial', sans-serif;
  font-size: 2rem;
  color: var(--primary-text-color);
}

section h3 {
  font-family: 'Questrial', sans-serif;
  font-size: 1.25rem;
  color: var(--button-color);
}

section p {
  color: var(--primary-text-color);
}

.multimedia-elements {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button-container {
  text-align: right;
  margin-top: 1rem;
}

.sales-chart {
  margin: 2rem 0;
  padding: 1rem;
  background: white;
  border-radius: 8px;
}

.sales-chart h4 {
  color: var(--primary-text-color);
  margin-bottom: 1rem;
  font-family: 'Questrial', sans-serif;
}

.chart {
  height: 300px;
  width: 100%;
}

.quick-overview {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.quick-overview h2 {
  font-family: 'Questrial', sans-serif;
  color: var(--primary-text-color);
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.quick-overview-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  align-items: start;
}

.overview-card {
  text-align: center;
  background: white;
  padding: 0.75rem;
  border-radius: 8px;
  min-width: 0;
}

.overview-card h3 {
  color: var(--primary-text-color);
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-family: 'Questrial', sans-serif;
}

.overview-value {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--primary-text-color);
  margin: 0.25rem 0 0 0;
}

.overview-subtitle {
  font-size: 0.8rem;
  color: var(--grey);
  margin: 0.15rem 0 0 0;
}

.overview-dashboard {
  margin-bottom: 2rem;
  background: var(--card-background);
  padding: 1.5rem;
  box-shadow: none;
}

.overview-dashboard:last-child {
  margin-bottom: 0;
}

.overview-dashboard h2 {
  margin-bottom: 0.5rem;
}

.overview-dashboard h3 {
  margin-bottom: 1rem;
}
.score {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.5rem;
}

.ai-summary {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.ai-summary h5 {
  color: var(--primary-text-color);
  margin-bottom: 0.5rem;
}

.reviews-section {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 1.5rem;
}

.reviews-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rating-score {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-text-color);
  margin: 0.5rem 0;
}

.rating-summary {
  text-align: center;
  font-size: 0.9rem;
  color: var(--grey);
  max-width: 80%;
  margin: 0 auto;
}

.sales-bars {
  margin: 10px 0;
  padding: 0 10px;
}

.sales-bar-container {
  height: 20px;
  background-color: var(--light-grey);
  border-radius: 10px;
  overflow: visible;
  position: relative;
  display: flex;
  margin: 20px 0;
}

.forecast-marker {
  position: absolute;
  top: -25px;
  transform: translateX(-50%);
  font-size: 0.8rem;
  color: var(--button-color);
  white-space: nowrap;
}

.forecast-marker::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  height: 35px;
  width: 2px;
  background-color: currentColor;
  opacity: 0.5;
}

.sales-comparison {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.comparison-indicator {
  font-size: 0.9rem;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  font-weight: 500;
  margin-left: 0.5rem;
}

.comparison-indicator.positive {
  color: #005432;
  background-color: #e5feea;
}

.comparison-indicator.negative {
  color: #FF9999;
  background-color: #fff2f2;
}

.sales-progress {
  height: 100%;
  transition: width 0.3s ease;
}

.sales-progress.main {
  z-index: 2;
  border-radius: 10px 0 0 10px;
}

.sales-progress.main:last-child {
  border-radius: 10px;
}

.sales-progress.unpaid {
  opacity: 0.7;
  border-radius: 0 10px 10px 0;
}

.sales-legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
}

.amount-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.quick-gauge-value {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--primary-text-color);
  margin: 0;
}

.unpaid-note {
  font-size: 0.75rem;
  color: #FF9999;
  font-weight: normal;
  margin: 0;
}

.quick-gauge-subtitle {
  font-size: 0.8rem;
  color: var(--grey);
  margin: 0;
}

.key-metrics {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.metric-label {
  font-size: 0.9rem;
  color: var(--grey);
}

.metric-value {
  font-size: 1.75rem;
  font-weight: bold;
}

.metric-divider {
  width: 1px;
  height: 40px;
  background-color: var(--light-grey);
}

.gauge-grid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.key-metrics {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.satisfaction-gauge {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
}

.metric-target {
  font-size: 0.8rem;
  color: var(--grey);
  margin-top: 0.25rem;
}

.business-details-button {
  text-decoration: none;
  display: inline-block;
  margin-top: 0.75rem;
}

.unpaid-note {
  font-size: 0.75rem;
  color: #FF9999;
  margin-left: 0.5rem;
  font-weight: normal;
}

.business-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.overview-dashboard {
  background: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.overview-dashboard.expanded {
  grid-column: 1 / -1;
  cursor: default;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.expand-indicator {
  color: var(--button-color);
  transition: transform 0.3s ease;
}

.expand-indicator.expanded {
  transform: rotate(180deg);
}

.basic-metrics {
  margin: 1rem 0;
}

.expanded-content {
  margin-top: 2rem;
  animation: slideDown 0.3s ease forwards;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.metric-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.metric-label {
  color: var(--grey);
  font-size: 0.9rem;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.metrics-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.key-metrics {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.metric-divider {
  width: 1px;
  height: 40px;
  background: var(--grey);
  opacity: 0.3;
}

.reviews-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.review-score {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.score-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--button-color);
}

.review-count {
  color: var(--grey);
  font-size: 0.9rem;
}

.review-summary {
  color: var(--primary-text-color);
  line-height: 1.5;
}

.metric-target {
  font-size: 0.8rem;
  color: var(--grey);
}

.summary-container h5 {
  color: var(--primary-text-color);
  margin-bottom: 0.5rem;
  font-family: 'Questrial', sans-serif;
}

.ai-note {
  display: block;
  color: var(--grey);
  font-size: 0.8rem;
  margin-top: 0.5rem;
  font-style: italic;
}

.quick-nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
}

.nav-block {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  color: var(--primary-text-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.nav-block:not(.disabled):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-block i {
  font-size: 2rem;
  color: var(--button-color);
  margin-bottom: 0.5rem;
}

.nav-block h3 {
  font-family: 'Questrial', sans-serif;
  font-size: 1.2rem;
  margin: 0;
}

.nav-block p {
  font-size: 0.9rem;
  color: var(--grey);
  margin: 0;
}

.nav-block.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.coming-soon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: var(--button-color);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.latest-order {
  font-size: 0.75rem;
  color: var(--grey);
  margin: 0.15rem 0 0 0;
}
</style>
