<template>
  <div class="user-management">
    <h3>Tilføj ny bruger</h3>
    
    <form @submit.prevent="addNewUser" class="add-user-form">
      <div class="form-group">
        <label for="email">Email</label>
        <input 
          type="email" 
          id="email" 
          v-model="newUser.email" 
          required
        >
      </div>

      <!-- Show organization selection only if more than one org -->
      <div v-if="organizations.length > 1" class="form-group">
        <label for="organization">Organisation</label>
        <select id="organization" v-model="newUser.organization" required @change="handleOrgChange">
          <option value="">Vælg organisation</option>
          <option v-for="org in organizations" 
                  :key="org.orgName" 
                  :value="org">
            {{ org.orgName }}
          </option>
        </select>
      </div>

      <div class="form-group" v-if="availableBusinesses.length > 0">
        <label>Vælg restauranter</label>
        <div class="business-checkboxes">
          <div v-for="business in availableBusinesses" 
               :key="business.name" 
               class="checkbox-item">
            <input type="checkbox"
                   :id="business.name"
                   :value="business"
                   v-model="newUser.selectedBusinesses">
            <label :for="business.name">{{ business.data?.businessName }}</label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="role">Rolle</label>
        <select id="role" v-model="newUser.role" required>
          <option value="user">Bruger</option>
          <option value="admin">Admin</option>
          <option value="owner">Ejer</option>
        </select>
      </div>

      <button type="submit" class="submit-button" :disabled="isCreating">
        <span v-if="isCreating" class="loader"></span>
        <span v-else>Tilføj bruger</span>
      </button>
    </form>

    <div v-if="message" :class="['message', message.type]">
      {{ message.text }}
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export default {
  name: 'UserManagement',
  props: {
    organizations: {
      type: Array,
      required: true
    }
  },
  
  setup(props) {
    const newUser = ref({
      email: '',
      role: 'user',
      organization: null,
      selectedBusinesses: []
    });

    const message = ref(null);
    const isCreating = ref(false);
    
    // Set default organization if only one exists
    onMounted(() => {
      if (props.organizations.length === 1) {
        newUser.value.organization = props.organizations[0];
      }
    });

    const availableBusinesses = computed(() => {
      const org = newUser.value.organization;
      if (!org) return [];
      
      // Transform businesses array to include data structure
      return org.businesses.map(business => ({
        name: business.name,
        data: business.data
      }));
    });

    const handleOrgChange = () => {
      newUser.value.selectedBusinesses = [];
    };

    const addNewUser = async () => {
      try {
        if (!newUser.value.organization || !newUser.value.selectedBusinesses.length) {
          message.value = {
            type: 'error',
            text: 'Please select an organization and at least one business'
          };
          return;
        }

        isCreating.value = true;
        const payload = {
          email: newUser.value.email,
          role: newUser.value.role,
          organization: {
            orgName: newUser.value.organization.orgName,
            businesses: newUser.value.selectedBusinesses.map(business => business.name)
          }
        };

        const functions = getFunctions(undefined, 'europe-west1');
        const createUserInOrg = httpsCallable(functions, 'createUserInOrg');
        const result = await createUserInOrg(payload);

        if (result.data.success) {
          // Send password reset email using client SDK
          const auth = getAuth();
          await sendPasswordResetEmail(auth, payload.email);
          
          message.value = {
            type: 'success',
            text: 'Bruger oprettet. Et link til at oprette adgangskode er sendt til brugerens email.'
          };
          // Reset form
          newUser.value = {
            email: '',
            role: 'user',
            organization: props.organizations.length === 1 ? props.organizations[0] : null,
            selectedBusinesses: []
          };
        }
      } catch (error) {
        let errorMessage = 'Unknown error occurred';
        
        if (error.code === 'already-exists') {
          errorMessage = 'This email is already registered';
        } else if (error.code === 'permission-denied') {
          errorMessage = 'You do not have permission to perform this action';
        } else if (error.details) {
          errorMessage = error.details;
        }

        message.value = {
          type: 'error',
          text: `Fejl: ${errorMessage} (${error.code})`
        };
      } finally {
        isCreating.value = false;
      }
    };

    return {
      newUser,
      message,
      availableBusinesses,
      handleOrgChange,
      addNewUser,
      isCreating
    };
  }
};
</script>

<style scoped>
.user-management {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-user-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 500px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

label {
  color: #003224;
  font-weight: 500;
}

input, select {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.9rem;
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-height: 42px;
  background-color: #005432;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-button:hover {
  background-color: #004428;
}

.message {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 6px;
}

.message.success {
  background-color: #e0f7e6;
  color: #005432;
}

.message.error {
  background-color: #ffe6e6;
  color: #cc0000;
}

.business-checkboxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.checkbox-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-item input[type="checkbox"] {
  width: auto;
}

.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>