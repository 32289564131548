<template>
  <div class="manage-users">
    <h3>Administrer brugere</h3>
    
    <div class="users-list">
      <div v-for="user in users" :key="user.id" class="user-card">
        <div class="user-header" @click="toggleUser(user.id)">
          <div class="user-basic-info">
            <span class="user-email">{{ user.email }}</span>
            <span class="user-role">({{ user.role }})</span>
            <span class="last-login">Seneste login: {{ formatLastLogin(user.lastLoginTime) }}</span>
          </div>
          <div class="user-actions-compact">
            <button @click.stop="confirmDelete(user)" class="delete-btn">
              Slet bruger
            </button>
            <span class="expand-icon" :class="{ 'is-expanded': expandedUsers[user.id] }">▼</span>
          </div>
        </div>

        <div v-if="expandedUsers[user.id]" class="user-details">
          <div class="role-selector">
            <label>Rolle:</label>
            <select v-model="user.role" @change="updateUserRole(user.id, user.role)">
              <option value="user">Bruger</option>
              <option value="admin">Admin</option>
              <option value="owner">Ejer</option>
            </select>
          </div>
          
          <div class="organizations-list">
            <div v-for="(orgData, orgId) in user.organizations" :key="orgId" class="org-item">
              <h4>{{ orgId }}</h4>
              <div class="businesses-list">
                <div v-for="business in orgData.businesses" :key="business" class="business-item">
                  {{ getBusinessName(orgId, business) }}
                  <button @click="removeBusinessAccess(user.id, orgId, business)" class="remove-btn">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <button @click="showAddBusinessModal(user)" class="add-btn">
            Tilføj forretning
          </button>
        </div>
      </div>
    </div>

    <!-- Add Business Modal -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <h4>Tilføj forretning til {{ selectedUser?.email }}</h4>
        
        <div class="org-selection">
          <select v-model="selectedOrg">
            <option value="">Vælg organisation</option>
            <option v-for="org in organizations" 
                    :key="org.orgName" 
                    :value="org">
              {{ org.orgName }}
            </option>
          </select>

          <div v-if="selectedOrg" class="business-selection">
            <div v-for="business in selectedOrg.businesses" 
                 :key="business.name" 
                 class="business-checkbox">
              <input type="checkbox" 
                     :id="business.name"
                     v-model="selectedBusinesses"
                     :value="business.name">
              <label :for="business.name">{{ business.name }}</label>
            </div>
          </div>
        </div>

        <div class="modal-actions">
          <button @click="addBusinessesToUser" class="save-btn">Gem</button>
          <button @click="closeModal" class="cancel-btn">Annuller</button>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="modal">
      <div class="modal-content">
        <h4>Slet bruger</h4>
        <p>Er du sikker på, at du vil slette {{ userToDelete?.email }}?</p>
        <p class="warning">Denne handling kan ikke fortrydes!</p>
        
        <div class="modal-actions">
          <button 
            @click="deleteUser" 
            class="delete-btn"
            :disabled="isDeleting"
          >
            <span v-if="isDeleting" class="loader"></span>
            <span v-else>Slet</span>
          </button>
          <button 
            @click="closeDeleteModal" 
            class="cancel-btn"
            :disabled="isDeleting"
          >
            Annuller
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db, doc, setDoc, getDoc } from '@/utils/firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default {
  name: 'ManageUsers',
  
  props: {
    organizations: {
      type: Array,
      required: true
    }
  },

  setup(props) {
    const users = ref([]);
    const expandedUsers = ref({});
    const showModal = ref(false);
    const showDeleteModal = ref(false);
    const selectedUser = ref(null);
    const userToDelete = ref(null);
    const selectedOrg = ref(null);
    const selectedBusinesses = ref([]);
    const isDeleting = ref(false);

    const getBusinessName = (orgId, businessId) => {
      const org = props.organizations.find(o => o.orgName === orgId);
      if (!org) return businessId;
      
      const business = org.businesses.find(b => b.name === businessId);
      return business?.data?.businessName || businessId;
    };

    const fetchUsers = async () => {
      try {
        const functions = getFunctions(undefined, 'europe-west1');
        const getUsersWithLoginTime = httpsCallable(functions, 'getAdminUsersList');
        const result = await getUsersWithLoginTime();
        users.value = result.data.users;
      } catch (error) {
        console.error('Error fetching users:', error);
        // Handle error appropriately
      }
    };

    const toggleUser = (userId) => {
      expandedUsers.value[userId] = !expandedUsers.value[userId];
    };

    const updateUserRole = async (userId, newRole) => {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, { role: newRole }, { merge: true });
    };

    const removeBusinessAccess = async (userId, orgId, businessId) => {
      const userRef = doc(db, 'users', userId);
      const userData = await getDoc(userRef);
      const updatedOrgs = { ...userData.data().organizations };
      
      const businesses = updatedOrgs[orgId].businesses.filter(b => b !== businessId);
      
      if (businesses.length === 0) {
        delete updatedOrgs[orgId];
      } else {
        updatedOrgs[orgId].businesses = businesses;
      }
      
      await setDoc(userRef, { organizations: updatedOrgs }, { merge: true });
      await fetchUsers();
    };

    const showAddBusinessModal = (user) => {
      selectedUser.value = user;
      selectedOrg.value = null;
      selectedBusinesses.value = [];
      showModal.value = true;
    };

    const addBusinessesToUser = async () => {
      if (!selectedUser.value || !selectedOrg.value || selectedBusinesses.value.length === 0) return;

      const userRef = doc(db, 'users', selectedUser.value.id);
      const userData = await getDoc(userRef);
      const currentOrgs = userData.data().organizations || {};

      const updatedOrgs = {
        ...currentOrgs,
        [selectedOrg.value.orgName]: {
          businesses: [
            ...(currentOrgs[selectedOrg.value.orgName]?.businesses || []),
            ...selectedBusinesses.value
          ]
        }
      };

      await setDoc(userRef, { organizations: updatedOrgs }, { merge: true });
      await fetchUsers();
      closeModal();
    };

    const confirmDelete = (user) => {
      userToDelete.value = user;
      showDeleteModal.value = true;
    };

    const deleteUser = async () => {
      if (!userToDelete.value) return;
      
      isDeleting.value = true;
      try {
        const functions = getFunctions(undefined, 'europe-west1');
        const deleteUserCompletely = httpsCallable(functions, 'deleteUserCompletely');
        
        await deleteUserCompletely({ userId: userToDelete.value.id });
        await fetchUsers();
        closeDeleteModal();
      } catch (error) {
        console.error('Error deleting user:', error);
        // You might want to show an error message to the user
      } finally {
        isDeleting.value = false;
      }
    };

    const closeModal = () => {
      showModal.value = false;
      selectedUser.value = null;
      selectedOrg.value = null;
      selectedBusinesses.value = [];
    };

    const closeDeleteModal = () => {
      showDeleteModal.value = false;
      userToDelete.value = null;
    };

    const formatLastLogin = (timestamp) => {
      if (!timestamp) return 'Aldrig';
      const date = new Date(timestamp);
      return date.toLocaleString('da-DK', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    };

    onMounted(() => {
      fetchUsers();
    });

    return {
      users,
      expandedUsers,
      showModal,
      showDeleteModal,
      selectedUser,
      userToDelete,
      selectedOrg,
      selectedBusinesses,
      getBusinessName,
      toggleUser,
      updateUserRole,
      removeBusinessAccess,
      showAddBusinessModal,
      addBusinessesToUser,
      confirmDelete,
      deleteUser,
      closeModal,
      closeDeleteModal,
      formatLastLogin,
      isDeleting
    };
  }
};
</script>

<style scoped>
.manage-users {
  margin-top: 2rem;
  padding: 1rem;
}

.user-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.user-basic-info {
  display: flex;
  flex-direction: column;
}

.user-email {
  font-weight: bold;
}

.user-role {
  color: #666;
}

.user-actions-compact {
  display: flex;
  gap: 0.5rem;
}

.delete-btn {
  background: #cc0000;
  color: white;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
}

.warning {
  color: #cc0000;
  font-weight: bold;
}

.delete-btn {
  background: #cc0000;
  color: white;
}

.save-btn {
  background: #005432;
  color: white;
}

button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.business-checkbox {
  margin: 0.5rem 0;
}

.last-login {
  font-size: 0.9em;
  color: var(--grey);
  display: block;
  margin-top: 0.25rem;
}

.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>