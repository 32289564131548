<template>
  <div class="admin-page">
    <header class="header-section">
      <h1>Administration</h1>
      <p class="subtitle">Administrer brugere og forretningsindstillinger</p>
    </header>

    <div class="admin-sections">
      <!-- Business Settings Section -->
      <section class="admin-section">
        <div class="section-header" @click="toggleSection('businessSettings')">
          <div class="header-content">
            <i class="fas fa-store"></i>
            <h2>Forretningsindstillinger</h2>
          </div>
          <div class="expand-icon" :class="{ 'is-expanded': expandedSections.businessSettings }">▼</div>
        </div>
        
        <div class="section-content" :class="{ 'is-expanded': expandedSections.businessSettings }">
          <!-- Salary Targets -->
          <section class="salary-targets">
            <h3>Løn Procent Mål</h3>
            <div v-for="business in userBusinesses" 
                 :key="business.id" 
                 class="business-targets"
            >
              <div class="business-header" @click="toggleBusiness(business.id)">
                <div class="header-content">
                  <h3>{{ business.businessData?.businessName }}</h3>
                  <div class="summary">
                    <span>Gennemsnit: {{ getAverageSalaryTarget(business) }}%</span>
                  </div>
                </div>
                <div class="expand-icon" :class="{ 'is-expanded': expandedBusinesses[business.id] }">
                  ▼
                </div>
              </div>
              
              <div class="business-content" 
                   :class="{ 'is-expanded': expandedBusinesses[business.id] }"
              >
                <div class="months-grid">
                  <div v-for="month in months" 
                       :key="month.value" 
                       class="month-input"
                  >
                    <label :for="`${business.id}-${month.value}`">{{ month.label }}</label>
                    <div class="input-group">
                      <input
                        type="number"
                        :id="`${business.id}-${month.value}`"
                        :value="salaryInputs[business.id]?.[month.value] || business.businessData?.salaryTarget?.[month.value] || 0"
                        @input="updateSalaryInput(business.id, month.value, $event)"
                        min="0"
                        max="100"
                        step="0.5"
                      >
                      <span class="percentage">%</span>
                    </div>
                  </div>
                </div>
                
                <button @click="saveSalaryTargets(business.id)" class="save-button">
                  Gem ændringer
                </button>
              </div>
            </div>
          </section>

          <!-- Closed Days -->
          <section class="closed-days">
            <h3>Lukkedage</h3>
            <div v-for="business in userBusinesses" 
                 :key="business.id" 
                 class="business-targets"
            >
              <div class="business-header" @click="toggleClosedDays(business.id)">
                <div class="header-content">
                  <h3>{{ business.businessData?.businessName }}</h3>
                  <div class="summary">
                    <span>Antal lukkedage: {{ getClosedDaysCount(business) }}</span>
                  </div>
                </div>
                <div class="expand-icon" :class="{ 'is-expanded': expandedClosedDays[business.id] }">
                  ▼
                </div>
              </div>

              <div class="business-content" 
                   :class="{ 'is-expanded': expandedClosedDays[business.id] }"
              >
                <div class="quick-select-options">
                  <h4>Mærkedage</h4>
                  <div class="common-dates">
                    <button 
                      v-for="(date, index) in commonClosedDates" 
                      :key="index"
                      class="quick-select-btn"
                      :class="{ 'selected': isCommonDateSelected(business.id, date.value) }"
                      @click="toggleCommonDate(business.id, date.value)"
                    >
                      {{ date.label }}
                    </button>
                  </div>
                </div>

                <div class="weekly-closures">
                  <h4>Ugedage der er lukket</h4>
                  <div class="weekday-buttons">
                    <button 
                      v-for="day in weekDays" 
                      :key="day.value"
                      class="quick-select-btn"
                      :class="{ 'selected': isWeekdaySelected(business.id, day.value) }"
                      @click="toggleWeekday(business.id, day.value)"
                    >
                      {{ day.label }}
                    </button>
                  </div>
                </div>

                <div class="closed-days-picker">
                  <h4>Vælg specifikke datoer</h4>
                  <v-date-picker
                    v-model="closedDays[business.id]"
                    mode="multiple"
                    :min-date="new Date()"
                    :attributes="calendarAttributes"
                  />
                </div>
                
                <button @click="saveClosedDays(business.id)" class="save-button">
                  Gem lukkedage
                </button>
              </div>
            </div>
          </section>
        </div>
      </section>

      <!-- User Management Section -->
      <section class="admin-section" v-if="isAuthorized">
        <div class="section-header" @click="toggleSection('userManagement')">
          <div class="header-content">
            <i class="fas fa-users-cog"></i>
            <h2>Brugeradministration</h2>
          </div>
          <div class="expand-icon" :class="{ 'is-expanded': expandedSections.userManagement }">▼</div>
        </div>
        
        <div class="section-content" :class="{ 'is-expanded': expandedSections.userManagement }">
          <div class="user-management-sections">
            <div class="add-user-section">
              <div class="section-header" @click="toggleSection('addUser')">
                <div class="header-content">
                  <i class="fas fa-user-plus"></i>
                  <h3>Tilføj ny bruger</h3>
                </div>
                <div class="expand-icon" :class="{ 'is-expanded': expandedSections.addUser }">▼</div>
              </div>
              <div class="section-content" :class="{ 'is-expanded': expandedSections.addUser }">
                <UserManagement :organizations="organizations" class="user-management-section" />
              </div>
            </div>
            
            <div class="manage-users-section">
              <ManageUsers :organizations="organizations" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import UserManagement from '@/components/UserManagement.vue';
import ManageUsers from '@/components/ManageUsers.vue';

export default {
  name: 'AdminPage',
  components: {
    UserManagement,
    ManageUsers
  },
  setup() {
    const store = useStore();
    const salaryInputs = ref({});
    const expandedBusinesses = ref({});
    const expandedClosedDays = ref({});
    const closedDays = ref({});
    const expandedSections = ref({
      businessSettings: false,
      userManagement: false,
      addUser: false
    });

    const months = [
      { value: '01', label: 'Januar' },
      { value: '02', label: 'Februar' },
      { value: '03', label: 'Marts' },
      { value: '04', label: 'April' },
      { value: '05', label: 'Maj' },
      { value: '06', label: 'Juni' },
      { value: '07', label: 'Juli' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'Oktober' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' }
    ];

    const userBusinesses = computed(() => {
      const businesses = [];
      store.state.organizations.forEach(org => {
        org.businesses.forEach(business => {
          businesses.push({
            id: business.name,
            businessData: business.data,
          });
        });
      });
      return businesses;
    });

    const updateSalaryInput = (businessId, month, event) => {
      if (!salaryInputs.value[businessId]) {
        salaryInputs.value[businessId] = {};
      }
      salaryInputs.value[businessId][month] = Number(event.target.value);
    };

    onMounted(() => {
      userBusinesses.value.forEach(business => {
        if (!salaryInputs.value[business.id]) {
          salaryInputs.value[business.id] = {};
        }
        months.forEach(month => {
          salaryInputs.value[business.id][month.value] = 
            business.businessData?.salaryTarget?.[month.value] || 0;
        });

        closedDays.value[business.id] = {
          specificDates: business.businessData?.closedDays?.specificDates || [],
          commonDates: business.businessData?.closedDays?.commonDates || [],
          weeklyClosures: business.businessData?.closedDays?.weeklyClosures || []
        };
      });
    });

    const saveSalaryTargets = async (businessId) => {
      const businessTargets = salaryInputs.value[businessId];
      for (const [month, percentage] of Object.entries(businessTargets)) {
        await store.dispatch('updateSalaryTarget', {
          businessId,
          month,
          percentage: Number(percentage)
        });
      }
    };

    const toggleBusiness = (businessId) => {
      expandedBusinesses.value[businessId] = !expandedBusinesses.value[businessId];
    };

    const getAverageSalaryTarget = (business) => {
      const targets = business.businessData?.salaryTarget || {};
      const values = Object.values(targets);
      if (values.length === 0) return 0;
      const sum = values.reduce((acc, val) => acc + val, 0);
      return (sum / values.length).toFixed(1);
    };

    const toggleClosedDays = (businessId) => {
      expandedClosedDays.value[businessId] = !expandedClosedDays.value[businessId];
    };

    const getClosedDaysCount = (business) => {
      const closedDays = business.businessData?.closedDays || {};
      return (
        (closedDays.specificDates?.length || 0) +
        (closedDays.commonDates?.length || 0) +
        (closedDays.weeklyClosures?.length || 0)
      );
    };

    const commonClosedDates = [
      { label: 'Nytårsdag (1. januar)', value: '01-01' },
      { label: 'Skærtorsdag', value: 'Skærtorsdag' },
      { label: 'Langfredag', value: 'Langfredag' },
      { label: 'Påskedag', value: 'Påskedag' },
      { label: '2. Påskedag', value: '2. påskedag' },
      { label: 'Store Bededag', value: 'Store Bededag' },
      { label: 'Kr. Himmelfartsdag', value: 'Kr. Himmelfartsdag' },
      { label: 'Pinsedag', value: 'Pinsedag' },
      { label: '2. Pinsedag', value: '2. Pinsedag' },
      { label: 'Juleaften (24. december)', value: '12-24' },
      { label: '1. Juledag (25. december)', value: '12-25' },
      { label: '2. Juledag (26. december)', value: '12-26' },
      { label: 'Nytårsaften (31. december)', value: '12-31' },
    ];

    const isCommonDateSelected = (businessId, dateValue) => {
      if (!closedDays.value[businessId]?.commonDates) {
        return false;
      }
      return closedDays.value[businessId].commonDates.includes(dateValue);
    };

    const toggleCommonDate = (businessId, dateValue) => {
      if (!closedDays.value[businessId]) {
        closedDays.value[businessId] = {};
      }
      
      if (!closedDays.value[businessId].commonDates) {
        closedDays.value[businessId].commonDates = [];
      }

      const index = closedDays.value[businessId].commonDates.indexOf(dateValue);
      if (index === -1) {
        closedDays.value[businessId].commonDates.push(dateValue);
      } else {
        closedDays.value[businessId].commonDates.splice(index, 1);
      }
    };

    const saveClosedDays = async (businessId) => {
      await store.dispatch('updateClosedDays', {
        businessId,
        closedDays: {
          commonDates: closedDays.value[businessId].commonDates || [],
          weeklyClosures: closedDays.value[businessId].weeklyClosures || [],
          specificDates: closedDays.value[businessId].specificDates || []
        }
      });
    };

    const calendarAttributes = [
      {
        dot: true,
        dates: new Date(),
        color: 'red'
      }
    ];

    const weekDays = [
      { label: 'Mandag', value: 'monday' },
      { label: 'Tirsdag', value: 'tuesday' },
      { label: 'Onsdag', value: 'wednesday' },
      { label: 'Torsdag', value: 'thursday' },
      { label: 'Fredag', value: 'friday' },
      { label: 'Lørdag', value: 'saturday' },
      { label: 'Søndag', value: 'sunday' }
    ];

    const isWeekdaySelected = (businessId, weekday) => {
      if (!closedDays.value[businessId]?.weeklyClosures) {
        return false;
      }
      return closedDays.value[businessId].weeklyClosures.includes(weekday);
    };

    const toggleWeekday = (businessId, weekday) => {
      if (!closedDays.value[businessId]) {
        closedDays.value[businessId] = {};
      }
      
      if (!closedDays.value[businessId].weeklyClosures) {
        closedDays.value[businessId].weeklyClosures = [];
      }

      const index = closedDays.value[businessId].weeklyClosures.indexOf(weekday);
      if (index === -1) {
        closedDays.value[businessId].weeklyClosures.push(weekday);
      } else {
        closedDays.value[businessId].weeklyClosures.splice(index, 1);
      }
    };

    const isAuthorized = computed(() => {
      const role = store.state.role;
      return role === 'admin' || role === 'owner';
    });

    const toggleSection = (section) => {
      expandedSections.value[section] = !expandedSections.value[section];
    };

    const organizations = computed(() => store.state.organizations || []);

    return {
      months,
      userBusinesses,
      salaryInputs,
      saveSalaryTargets,
      updateSalaryInput,
      expandedBusinesses,
      toggleBusiness,
      getAverageSalaryTarget,
      expandedClosedDays,
      closedDays,
      toggleClosedDays,
      saveClosedDays,
      getClosedDaysCount,
      calendarAttributes,
      commonClosedDates,
      isCommonDateSelected,
      toggleCommonDate,
      weekDays,
      isWeekdaySelected,
      toggleWeekday,
      isAuthorized,
      expandedSections,
      toggleSection,
      organizations,
    };
  }
};
</script>

<style scoped>
.admin-page {
  padding: 2rem;
  background-color: var(--background-color);
  min-height: 100vh;
}

.page-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.header-section {
  text-align: center;
  margin-bottom: 2rem;
}

.header-section h1 {
  font-family: 'Questrial', sans-serif;
  font-size: 2.5rem;
  color: var(--primary-text-color);
  margin-bottom: 0.5rem;
}

.header-section .subtitle {
  color: var(--grey);
  font-size: 1.1rem;
  margin: 0;
}

.admin-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.section-header {
  background: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-header:hover {
  background: var(--card-hover-background);
}

.header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-content i {
  color: var(--button-color);
  font-size: 1.2rem;
}

.header-content h2 {
  color: var(--primary-text-color);
  margin: 0;
  font-family: 'Questrial', sans-serif;
  font-size: 1.5rem;
}

.section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background: var(--card-background);
  border-radius: 0 0 12px 12px;
  margin-top: -12px;
}

.section-content.is-expanded {
  max-height: 5000px;
  padding: 2rem;
}

.business-targets {
  background: var(--card-background);
  border-radius: 12px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px var(--shadow-color);
  overflow: hidden;
  transition: all 0.3s ease;
}

.business-targets:hover {
  box-shadow: 0 4px 8px var(--shadow-color);
}

.business-header {
  padding: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--card-background);
  transition: background-color 0.2s ease;
}

.business-header:hover {
  background: var(--background-color);
}

.expand-icon {
  color: var(--button-color);
  transition: transform 0.3s ease;
  font-size: 0.8rem;
}

.expand-icon.is-expanded {
  transform: rotate(180deg);
}

.business-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background: var(--card-background);
}

.business-content.is-expanded {
  max-height: 1000px;
  padding: 1.5rem;
}

.save-button {
  background-color: var(--button-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 0.9rem;
}

.save-button:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .admin-page {
    padding: 1rem;
  }
  
  .header-section h1 {
    font-size: 2rem;
  }
  
  .section-content.is-expanded {
    padding: 1rem;
  }
  
  .business-content.is-expanded {
    padding: 1rem;
  }
}

.closed-days {
  margin-top: 2rem;
}

.closed-days-picker {
  background: var(--card-background);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.quick-select-options {
  margin-bottom: 2rem;
}

.common-dates {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.quick-select-btn {
  padding: 0.5rem 1rem;
  border: 1px solid var(--button-color);
  border-radius: 20px;
  background: var(--card-background);
  color: var(--button-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.quick-select-btn:hover {
  background: var(--background-color);
}

.quick-select-btn.selected {
  background: var(--button-color);
  color: white;
}

h4 {
  color: var(--primary-text-color);
  margin-bottom: 1rem;
}

.weekly-closures {
  margin-bottom: 2rem;
}

.weekday-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .weekday-buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 480px) {
  .weekday-buttons {
    grid-template-columns: repeat(3, 1fr);
  }
}

.admin-section {
  margin-bottom: 3rem;
}

.admin-section h2 {
  color: var(--primary-text-color);
  margin-bottom: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  border-bottom: 2px solid var(--background-color);
  padding-bottom: 0.5rem;
}

.admin-section h3 {
  color: var(--primary-text-color);
  margin: 2rem 0 1rem;
  font-family: 'Montserrat', sans-serif;
}

.section-header {
  background: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.section-content.is-expanded {
  max-height: 5000px; /* Adjust based on content */
}

.expand-icon {
  color: var(--button-color);
  transition: transform 0.3s ease;
}

.expand-icon.is-expanded {
  transform: rotate(180deg);
}

.header-section {
  margin-bottom: 2rem;
}

.header-section h1 {
  color: var(--primary-text-color);
  margin-bottom: 0.5rem;
}

.header-section .subtitle {
  color: var(--grey);
  font-size: 1.1rem;
}

.admin-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.section-header {
  background: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.section-header:hover {
  background: var(--card-hover-background);
}

.header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-content i {
  color: var(--button-color);
  font-size: 1.2rem;
}

.section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background: var(--card-background);
  border-radius: 0 0 12px 12px;
  margin-top: -12px;
}

.section-content.is-expanded {
  max-height: 5000px;
  padding: 2rem;
}

.user-management-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.add-user-section, 
.manage-users-section {
  width: 100%;
  background: var(--card-background);
  border-radius: 12px;
  overflow: hidden;
}

.section-content.is-expanded {
  max-height: 5000px;
  padding: 2rem;
}

.months-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.month-input {
  flex: 1 1 calc(25% - 1rem);
  min-width: 150px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.input-group input {
  width: 80px;
  padding: 0.5rem;
  border: 1px solid var(--input-border);
  border-radius: 4px;
}

.percentage {
  color: var(--grey);
}
</style>
