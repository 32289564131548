import { createStore } from 'vuex';
import { db, doc, getDoc, setDoc, onAuthStateChanged, auth } from './firebaseConfig';

export default createStore({
  state: {
    user: null,
    role: null,
    organizations: [],
    salaryTargets: {}
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setRole(state, role) {
      state.role = role;
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations;
    },
    SET_SALARY_TARGETS(state, targets) {
      state.salaryTargets = targets;
    },
    UPDATE_SALARY_TARGET(state, { businessId, month, percentage }) {
      if (!state.salaryTargets[businessId]) {
        state.salaryTargets[businessId] = {};
      }
      state.salaryTargets[businessId][month] = percentage;
    },
    UPDATE_CLOSED_DAYS(state, { businessId, closedDays }) {
      state.organizations.forEach(org => {
        org.businesses.forEach(business => {
          if (business.name === businessId) {
            if (!business.data) business.data = {};
            business.data.closedDays = closedDays;
          }
        });
      });
    }
  },
  actions: {
    checkUser({ commit }) {
      return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
          commit('setUser', user);
          if (user) {
            try {
              const userRef = doc(db, 'users', user.uid);
              const userSnap = await getDoc(userRef);
  
              if (userSnap.exists()) {
                const userData = userSnap.data();
                commit('setRole', userData.role);
  
                const organizationsData = [];
                const colorSchemes = {};
  
                // Handle new organization structure
                if (userData.organizations) {
                  for (const [orgName, orgData] of Object.entries(userData.organizations)) {
                    try {
                      const orgRef = doc(db, 'organizations', orgName);
                      const orgSnap = await getDoc(orgRef);
  
                      if (orgSnap.exists()) {
                        const orgDocData = orgSnap.data();
                        const orgInfo = {
                          orgName,
                          businesses: [],
                          defaultColorScheme: orgDocData.defaultColorScheme,
                        };
  
                        // Fetch businesses
                        if (orgData.businesses && orgDocData.businesses) {
                          for (const businessName of orgData.businesses) {
                            const businessData = orgDocData.businesses[businessName];
                            if (businessData) {
                              orgInfo.businesses.push({
                                name: businessName,
                                data: {
                                  ...businessData,
                                  salaryTarget: businessData.salaryTarget || {}
                                },
                              });
  
                              // Store color schemes
                              colorSchemes[`${orgName}_${businessName}`] = businessData.colorScheme;
                            }
                          }
                        }
  
                        organizationsData.push(orgInfo);
                      }
                    } catch (orgError) {
                      console.error(`Error fetching organization ${orgName}:`, orgError);
                    }
                  }
                }
  
                commit('setOrganizations', organizationsData);
                commit('setColorSchemes', colorSchemes);
              }
            } catch (error) {
              console.error('Error fetching user data:', error);
              commit('setRole', null);
              commit('setOrganizations', []);
            }
          } else {
            commit('setRole', null);
            commit('setOrganizations', []);
          }
          resolve();
        });
      });
    },
    async fetchSalaryTargets({ commit }) {
      try {
        const salaryTargetsRef = doc(db, 'salaryTargets', 'targets');
        const snapshot = await getDoc(salaryTargetsRef);
        if (snapshot.exists()) {
          commit('SET_SALARY_TARGETS', snapshot.data());
        } else {
          commit('SET_SALARY_TARGETS', {});
        }
      } catch (error) {
        console.error('Error fetching salary targets:', error);
      }
    },
    async updateSalaryTarget({ commit, state }, { businessId, month, percentage }) {
      try {
        // Find the organization that contains this business
        let targetOrgId;
        state.organizations.forEach(org => {
          org.businesses.forEach(business => {
            if (business.name === businessId) {
              targetOrgId = org.orgName;
            }
          });
        });

        if (!targetOrgId) {
          throw new Error('Organization not found for business');
        }

        const orgRef = doc(db, 'organizations', targetOrgId);
        const orgSnap = await getDoc(orgRef);
        
        if (orgSnap.exists()) {
          const orgData = orgSnap.data();
          
          // Initialize or update the salary target
          if (!orgData.businesses[businessId].salaryTarget) {
            orgData.businesses[businessId].salaryTarget = {};
          }
          
          orgData.businesses[businessId].salaryTarget[month] = percentage;
          
          // Update Firestore
          await setDoc(orgRef, orgData);
          
          // Update local state
          commit('UPDATE_SALARY_TARGET', { businessId, month, percentage });
        }
      } catch (error) {
        console.error('Error updating salary target:', error);
      }
    },
    async updateClosedDays({ commit, state }, { businessId, closedDays }) {
      try {
        let targetOrgId;
        state.organizations.forEach(org => {
          org.businesses.forEach(business => {
            if (business.name === businessId) {
              targetOrgId = org.orgName;
            }
          });
        });

        if (!targetOrgId) {
          throw new Error('Organization not found for business');
        }

        const orgRef = doc(db, 'organizations', targetOrgId);
        const orgSnap = await getDoc(orgRef);
        
        if (orgSnap.exists()) {
          const orgData = orgSnap.data();
          
          // Initialize if needed
          if (!orgData.businesses[businessId].closedDays) {
            orgData.businesses[businessId].closedDays = {
              commonDates: [],
              weeklyClosures: [],
              specificDates: []
            };
          }
          
          // Update with new structure
          orgData.businesses[businessId].closedDays = closedDays;
          
          // Update Firestore
          await setDoc(orgRef, orgData);
          
          // Update local state
          commit('UPDATE_CLOSED_DAYS', { businessId, closedDays });
        }
      } catch (error) {
        console.error('Error updating closed days:', error);
      }
    }
  },
  getters: {
    // ... your existing getters ...
  }
});
