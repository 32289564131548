<template>
  <div class="navbar">
    <div class="nav-top">
      <img class="logo" src="../assets/images/light_green_logomark.png" @click="$router.push('/home')">
      <div class="expand-indicator">
        <i class="fas fa-chevron-right"></i>
      </div>
      <nav class="navigation">
        <!-- Primary Navigation Links -->
        <router-link class="nav-link" to="/home">
          <div class="header-content">
            <i class="fas fa-home section-icon"></i>
            <span>Home</span>
          </div>
        </router-link>
        <router-link class="nav-link" to="/forecast">
          <div class="header-content">
            <i class="fas fa-calendar-alt section-icon"></i>
            <span>Forecast</span>
          </div>
        </router-link>
        <router-link class="nav-link" to="/rapporter">
          <div class="header-content">
            <i class="fas fa-chart-line section-icon"></i>
            <span>Rapporter</span>
          </div>
        </router-link>

        <!-- Businesses Section -->
        <div class="section">
          <div class="section-header" @click="toggleBusinesses">
            <div class="header-content">
              <i class="fas fa-utensils section-icon"></i>
              <span>Restauranter</span>
            </div>
            <i class="fas fa-chevron-right toggle-icon" :class="{ 'rotated': businessesExpanded }"></i>
          </div>
          <div class="section-content" :class="{ 'expanded': businessesExpanded }">
            <router-link 
              v-for="business in userBusinesses" 
              :key="business.id"
              class="nav-link section-link" 
              :to="`/business/${encodeURIComponent(business.businessData?.businessName)}`"
            >
              {{ business.businessData?.businessName }}
            </router-link>
          </div>
        </div>

        <!-- Settings Section -->
        <div class="section">
          <div class="section-header" @click="toggleSettings">
            <div class="header-content">
              <i class="fas fa-cog section-icon"></i>
              <span>Indstillinger</span>
            </div>
            <i class="fas fa-chevron-right toggle-icon" :class="{ 'rotated': settingsExpanded }"></i>
          </div>
          <div class="section-content" :class="{ 'expanded': settingsExpanded }">
            <router-link class="nav-link section-link" to="/user">Bruger</router-link>
            <router-link class="nav-link section-link" to="/settings">Systemindstillinger</router-link>
            <router-link class="nav-link section-link" to="/admin" v-if="hasAdminAccess">Administration</router-link>
          </div>
        </div>
      </nav>
    </div>
    
    <div class="nav-bottom">
      <GreenButton @click="logout" borderRadius="4px">Log ud</GreenButton>
    </div>
  </div>
</template>

<script>
import { auth } from '@/utils/firebaseConfig.js';
import GreenButton from './GreenButton.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: "UserNavbar",
  components: {
    GreenButton
  },
  setup() {
    const store = useStore();
    
    const hasAdminAccess = computed(() => {
      const role = store.state.role;
      return role === 'admin' || role === 'owner';
    });

    return {
      hasAdminAccess
    };
  },
  data() {
    return {
      settingsExpanded: false,
      businessesExpanded: false,
    }
  },
  computed: {
    userBusinesses() {
      return this.$store.state.organizations.flatMap(org => 
        org.businesses.map(business => ({
          id: business.id || business.name,
          businessData: business.data
        }))
      );
    }
  },
  methods: {
    logout() {
      auth.signOut().then(() => {
        this.$store.commit('setUser', null); 
        this.$router.push({ name: 'HomepageFirstPage' });
      });
    },
    toggleSettings() {
      this.settingsExpanded = !this.settingsExpanded;
    },
    toggleBusinesses() {
      this.businessesExpanded = !this.businessesExpanded;
    }
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 12rem;
  padding: 1rem;
  background-color: var(--primary-text-color);
  transition: transform 0.3s ease;
  transform: translateX(-9rem);
}

.navbar:hover {
  transform: translateX(0);
}

.nav-top, .nav-bottom {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.logo {
  width: 5rem;
  height: 5rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.nav-link, .section-header {
  text-decoration: none;
  color: var(--button-text-color);
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  transition: all 0.2s ease;
  width: 100%;
}

.nav-link:hover, .section-header:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--button-color);
}

.section {
  margin: 0;
  padding: 0;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: var(--button-text-color);
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  width: 100%;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.section-icon {
  font-size: 1rem;
  width: 1.2rem;
  text-align: center;
}

.toggle-icon {
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.toggle-icon.rotated {
  transform: rotate(90deg);
}

.section-content {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  gap: 0.25rem;
}

.section-content.expanded {
  max-height: 500px;
}

.section-link {
  display: block;
  padding: 0.5rem 0.75rem;
  margin: 0;
  font-size: 0.9rem;
  opacity: 0.9;
  width: 100%;
}

.signout-button {
  width: 100%;
  color: var(--button-text-color);
  background-color: var(--button-color);
  border: none;
  padding: .5rem 1rem;
  margin-bottom: 2.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.signout-button:hover {
  background-color: var(--button-text-color);
  color: var(--button-color);
}

.router-link-active {
  color: var(--button-text-color);
  font-weight: 700;
  border-left: 2px solid var(--button-text-color);
  padding-left: calc(0.75rem - 2px);
}

.header-icons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.toggle-icon {
  font-size: 0.8rem;
}

.nav-bottom {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 2.5rem;
}

/* Override GreenButton hover color specifically in UserNavbar */
:deep(.green-button::before) {
  background-color: var(--button-text-color) !important;
}

:deep(.green-button:hover) {
  color: var(--button-color);
}

.navbar:not(:hover) .nav-link span,
.navbar:not(:hover) .section-header span {
  display: none;
}

.expand-indicator {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--button-text-color);
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.navbar:hover .expand-indicator {
  opacity: 0;
}

/* Ensure icons remain centered when text is hidden */
.navbar:not(:hover) .header-content {
  justify-content: center;
}

.navbar:not(:hover) .section-icon {
  margin-right: 0;
}

/* Hide toggle icons when collapsed */
.navbar:not(:hover) .toggle-icon {
  display: none;
}
</style>
