<template>
  <header class="header">
    <img class="logo" src="../assets/images/green_main_logo.png" @click="$router.push('/')">
    <nav class="navigation desktop-nav">
      <div class="nav-links">
        <router-link class="nav-link" to="/om-databite">Om Databite</router-link>
        <router-link class="nav-link" to="/product">Produkter</router-link>
        <router-link class="nav-link" to="/cases">Cases</router-link>
        <router-link class="nav-link" to="/contact">Kontakt os</router-link>
      </div>
      <div class="login-section">
        <GreenButton @click="showLoginForm = true">Log ind</GreenButton>
        <LoginForm v-if="showLoginForm" @close="showLoginForm = false" @login-success="showLoginForm = false" />
      </div>
    </nav>

    <BurgerMenu class="burger-menu" @click="toggleMenu">
      <template v-slot:default>
        <router-link class="nav-link" to="/om-databite">Om Databite</router-link>
        <router-link class="nav-link" to="/product">Product</router-link>
        <router-link class="nav-link" to="/cases">Cases</router-link>
        <router-link class="nav-link" to="/contact">Contact Us</router-link>
        <GreenButton @click="showLoginForm = true">Log In</GreenButton>
        <LoginForm v-if="showLoginForm" @close="showLoginForm = false" @login-success="showLoginForm = false" />
      </template>
    </BurgerMenu>
  </header>
</template>


<script>
import GreenButton from './GreenButton.vue';
import LoginForm from './LoginForm.vue';
import BurgerMenu from './BurgerMenu.vue';

export default {
  components: {
    GreenButton,
    LoginForm,
    BurgerMenu
  },
  data() {
    return {
      showLoginForm: false
    }
  },
  methods: {
    toggleMenu() {
    }
  }
};
</script>

<style scoped>
.desktop-nav {
  display: flex;
  justify-content: space-between;
}

.burger-menu {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .desktop-nav {
    display: none !important;
  }
  .burger-menu {
    display: block !important;
  }
}

@media screen and (min-width: 769px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px;
  }

  .navigation {
    display: flex;
    justify-content: flex-start; 
    gap: 2rem; /* Adjust as needed */
  }

  .login-section {
    display: flex;
    align-items: center;
    margin-left: auto; 
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add this to create space below the fixed header */
:root {
  --header-height: 100px; /* Adjust this value based on your header's height */
}

/* Add this to your global styles or App.vue */
#app {
  padding-top: var(--header-height);
}

.navigation {
  display: flex;
  justify-content: flex-start; /* Aligns navigation to the left */
  gap: 2rem; /* Adjust as needed */
}

.login-section {
  display: flex;
  align-items: center;
  margin-left: auto; /* Pushes login section to the right */
}

.logo {
  height: 3.5rem; /* Adjust as needed */
  cursor: pointer;
}
.nav-link {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  color: var(--primary-text-color);
}

.nav-links {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  gap: 2rem;
}
</style>
