<template>
  <div class="user-profile">
    <header class="header-section">
      <h1>Min Profil</h1>
      <p class="subtitle">Administrer din profil og se tilknyttede restauranter</p>
    </header>

    <main class="profile-content">
      <!-- Basic Information Card -->
      <section class="profile-section">
        <div class="section-header">
          <div class="header-content">
            <i class="fas fa-user section-icon"></i>
            <h2>Grundlæggende Information</h2>
          </div>
        </div>
        <div class="info-grid">
          <div class="info-card">
            <span class="info-label">Navn</span>
            <span class="info-value">{{ user?.displayName || 'Ikke angivet' }}</span>
          </div>
          <div class="info-card">
            <span class="info-label">Email</span>
            <span class="info-value">{{ user?.email }}</span>
          </div>
          <div class="info-card">
            <span class="info-label">Rolle</span>
            <span class="info-value">{{ formatRole(role) }}</span>
          </div>
        </div>
      </section>

      <!-- Organizations & Businesses Section -->
      <section class="profile-section">
        <div class="section-header">
          <div class="header-content">
            <i class="fas fa-building section-icon"></i>
            <h2>Tilknyttede Restauranter</h2>
          </div>
        </div>

        <div v-if="userBusinesses.length > 0" class="businesses-list">
          <router-link 
            v-for="business in userBusinesses" 
            :key="business.id"
            :to="`/business/${encodeURIComponent(business.businessData?.businessName)}`"
            class="business-card"
            :style="{ 
              backgroundColor: business.businessData?.colorScheme?.primary || '#005432',
              color: 'white'
            }"
          >
            <div class="business-content">
              <h4>{{ business.businessData?.businessName }}</h4>
              
              <div class="business-stats" v-if="business.data">
                <div class="stat-item">
                  <span class="stat-label">Dagens omsætning</span>
                  <span class="stat-value">
                    {{ formatCurrency(getTotalDailySales(business)) }}
                  </span>
                </div>
                <div class="stat-item">
                  <span class="stat-label">7 dages index</span>
                  <span class="stat-value">
                    {{ business.data?.prevWeekSales?.index || 0 }}
                  </span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div v-else class="no-data">
          <p>Ingen restauranter tilknyttet denne konto.</p>
        </div>
      </section>

      <!-- Password Change Section -->
      <section class="profile-section">
        <div class="section-header" @click="togglePasswordSection">
          <div class="header-content">
            <i class="fas fa-key section-icon"></i>
            <h2>Skift adgangskode</h2>
          </div>
          <div class="expand-icon" :class="{ 'is-expanded': isPasswordSectionExpanded }">▼</div>
        </div>
        <div class="section-content" :class="{ 'is-expanded': isPasswordSectionExpanded }">
          <ChangePasswordForm />
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ChangePasswordForm from '@/components/ChangePasswordForm.vue';

export default {
  name: 'UserProfile',
  components: {
    ChangePasswordForm
  },
  setup() {
    const store = useStore();
    const isPasswordSectionExpanded = ref(false);

    const togglePasswordSection = () => {
      isPasswordSectionExpanded.value = !isPasswordSectionExpanded.value;
    };

    const userBusinesses = computed(() => {
      const businesses = [];
      store.state.organizations.forEach(org => {
        org.businesses.forEach(business => {
          businesses.push({
            id: business.name,
            businessData: business.data,
          });
        });
      });
      return businesses;
    });

    const formatRole = (role) => {
      const roles = {
        user: 'Bruger',
        admin: 'Administrator',
        owner: 'Ejer'
      };
      return roles[role] || role;
    };

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK',
        maximumFractionDigits: 0
      }).format(amount);
    };

    const getTotalDailySales = (business) => {
      const dailySales = business.data?.dailySales;
      if (!dailySales) return 0;
      return (dailySales.paid?.amount || 0) + (dailySales.notPaid?.amount || 0);
    };

    return {
      user: computed(() => store.state.user),
      role: computed(() => store.state.role),
      userBusinesses,
      formatRole,
      formatCurrency,
      getTotalDailySales,
      isPasswordSectionExpanded,
      togglePasswordSection,
    };
  }
};
</script>

<style scoped>
.user-profile {
  padding: 2rem;
  background-color: var(--background-color);
}

.header-section {
  margin-bottom: 2rem;
}

.header-section h1 {
  font-family: 'Questrial', sans-serif;
  font-size: 2.5rem;
  color: var(--primary-text-color);
  margin: 0;
}

.subtitle {
  color: var(--grey);
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

.profile-section {
  background: var(--card-background);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px var(--shadow-color);
  margin-bottom: 2rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  background: var(--card-background);
  border-radius: 12px;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.section-icon {
  color: var(--button-color);
  font-size: 1.2rem;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.info-card {
  background: var(--background-color);
  padding: 1.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-label {
  color: var(--grey);
  font-size: 0.9rem;
}

.info-value {
  font-size: 1.2rem;
  color: var(--primary-text-color);
  font-weight: 500;
}

.businesses-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.business-card {
  border-radius: 12px;
  padding: 1.5rem;
  transition: transform 0.2s ease;
  text-decoration: none;
  cursor: pointer;
}

.business-card:hover {
  transform: translateY(-2px);
}

.business-content h4 {
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  text-align: center;
  font-family: 'Questrial', sans-serif;
  color: inherit;
}

.business-stats {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.stat-label {
  opacity: 0.9;
}

.stat-value {
  font-weight: bold;
}

.business-link {
  display: block;
  text-align: center;
}

.no-data {
  text-align: center;
  color: var(--grey);
  padding: 2rem;
}

@media (max-width: 768px) {
  .businesses-list {
    grid-template-columns: 1fr;
  }
  
  .info-grid {
    grid-template-columns: 1fr;
  }
}

.expand-icon {
  transition: transform 0.3s ease;
}

.expand-icon.is-expanded {
  transform: rotate(180deg);
}

.section-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background: var(--card-background);
  border-radius: 0 0 12px 12px;
  margin-top: -12px;
}

.section-content.is-expanded {
  max-height: 500px;
  padding: 2rem;
}
</style>
